import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Row } from '../components/helpers';
import Breathability from '../../src/Icons/Breathability';
import Cooling from '../../src/Icons/Cooling';
import Craftsmanship from '../../src/Icons/Craftsmanship';
import EcoFriendly from '../../src/Icons/EcoFriendly';
import Hypoallergenic from '../../src/Icons/Hypoallergenic';
import SlowMotion from '../../src/Icons/SlowMotion';
import Material from '../../src/Icons/Material';
import Relief from '../../src/Icons/Relief';
import Support from '../../src/Icons/Support';
import TempRegulation from '../../src/Icons/TempRegulation';
import DualCooling from '../../src/Icons/DualCooling';
import TripleCooling from '../../src/Icons/TripleCooling';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Icon from 'mfcl/Icons/[Icon Name]'
`}</code></pre>
    <Playground __position={0} __code={'<Row>\n  <Breathability width={30} height={30} />\n  <Craftsmanship width={30} height={30} />\n  <EcoFriendly width={30} height={30} />\n  <SlowMotion width={30} height={30} />\n  <Material width={30} height={30} />\n  <Relief width={30} height={30} />\n  <Support width={30} height={30} />\n  <TempRegulation width={30} height={30} />\n</Row>\n<br />\n<br />\n<Row>\n  <Hypoallergenic width={30} height={30} />\n  <Cooling width={30} height={30} />\n  <DualCooling width={30} height={30} />\n  <TripleCooling width={30} height={30} />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      Breathability,
      Cooling,
      Craftsmanship,
      EcoFriendly,
      Hypoallergenic,
      SlowMotion,
      Material,
      Relief,
      Support,
      TempRegulation,
      DualCooling,
      TripleCooling,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Breathability width={30} height={30} mdxType="Breathability" />
    <Craftsmanship width={30} height={30} mdxType="Craftsmanship" />
    <EcoFriendly width={30} height={30} mdxType="EcoFriendly" />
    <SlowMotion width={30} height={30} mdxType="SlowMotion" />
    <Material width={30} height={30} mdxType="Material" />
    <Relief width={30} height={30} mdxType="Relief" />
    <Support width={30} height={30} mdxType="Support" />
    <TempRegulation width={30} height={30} mdxType="TempRegulation" />
  </Row>
  <br />
  <br />
  <Row mdxType="Row">
    <Hypoallergenic width={30} height={30} mdxType="Hypoallergenic" />
    <Cooling width={30} height={30} mdxType="Cooling" />
    <DualCooling width={30} height={30} mdxType="DualCooling" />
    <TripleCooling width={30} height={30} mdxType="TripleCooling" />
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      